 
 .loginPage{
    width: 100%;
    height: 100vh;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
 } 
  
  .loginPagebutton{
    width: 100%;
    
  }
  .loginPage .input-group {
    position: relative;
    margin-bottom: 20px;
  } 
  .loginPage .input-group input {
    outline: none;
    display: block;
    width: 100%;
    height: 40px;
    position: relative;
    z-index: 3;
    border: 1px solid #d9d9d9;
    padding: 10px 10px;
    background: #ffffff;
    box-sizing: border-box;
    font-wieght: 400;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
  } 
  .loginPage .wrapper {
    width: 320px;
    background: #fff; 
    min-height: 200px;
    border: 1px solid #f3f3f3;
  }
  .loginPage .wrapper .inner-warpper {
    padding: 20px 30px 60px;
    box-shadow: 1px 1.732px 10px 0px rgba(0, 0, 0, 0.063);
  }
  .loginPage .wrapper .title {
    margin-top: 0;
  } 
  .sidebar-link.icon > span#chat-count {
    position: absolute;
    right: 15px;
}