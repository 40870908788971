.p-datatable .p-datatable-thead>tr>th {
  background-color: var(--primary-bg-color);
  color: var(--primary-color);
  font-weight: normal;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: var(--primary-color);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
  color: var(--primary-color);
}

.p-paginator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0;
}

.p-datatable .p-datatable-tbody>tr>td,
.p-datatable .p-datatable-thead>tr>th {
  border: 1px solid var(--border-color);
  padding: 8px 10px;
  text-align: center;
}

.p-datatable .p-column-header-content {
  justify-content: center;
}

.p-dropdown {
  background: #fff;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--primary-color);
  background: var(--primary-bg-color);
}

.p-paginator,
.p-dropdown-panel,
.p-datatable .p-datatable-tbody>tr {
  background: #fff;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #495057;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2.357rem;
  height: 2.357rem;
  border-radius: 4px;
}
.p-button { 
  background: var(--primary-bg-color);
  border: 1px solid var(--primary-bg-color); 
}

.p-toast .p-toast-message.p-toast-message-success {
  background: rgb(228 248 240);
}
.p-toast-message-content {
  display: flex;
  align-items: center;
}
.table-action.p-button.p-button-icon-only {
  width: 39px;
  padding: 0.75rem 0;
}
.table-action.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 39px;
}
.delete-confirm .p-toast .p-toast-message .p-toast-icon-close{
  position: absolute;
    top: 3px;
    right: 3px;
}
.p-toast-icon-close{
  position: absolute;
  top: 3px;
  right: 3px;
  color: var(--primary-bg-color);;
}
.p-toast .p-toast-message.p-toast-message-info {
  background: rgb(255 255 255);
  border: solid var(--primary-bg-color);
  border-width: 0 0 0 6px;
  color: var(--primary-bg-color);;
}
.p-badge { 
  color: #ffffff !important;
  font-size: 0.75rem !important; 
  border-radius: 10px !important; 
    padding: 0 0.5rem !important;
}