@import "/node_modules/primereact/resources/themes/lara-light-cyan/theme.css";

:root {
  --sidebarWidth: 200px;
  --topbarWidth: 0px;
  --primary-bg-color: #164863;
  --sidebar-bg-color: #164863;
  --primary-color: #fff;
  --border-color: #ccc;
  --chat-sender-bg-color: #f5f5f5;
  --chat-active-color: #00DB75;
  --secondary-bg-color: #f5f5f5
}


body {
  margin: 0;
  padding: 0;
}

.text-primary {
  color: var(--primary-bg-color);
}

.button-danger {
  background: #dc3545; 
  color: #ffffff; 
}
.button{
  cursor: pointer;
  border: 0;
  padding: 10px 15px; 
  font-size: 20px;
  transition: 0.3s linear;
  border-radius: 4px;
}
.button-outline-danger {
  border: 1px solid #dc3545; 
  color: #dc3545; 
  background: transparent;
}
.button-primary {
 
  background: var(--primary-bg-color); 
  color: var(--primary-color);
}

 

.App {
  display: flex;
  flex-wrap: wrap;
}

.custom-container {
  width: calc(100% - var(--sidebarWidth));
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--secondary-bg-color);
}

.sideBar {
  height: 100vh;
  width: var(--sidebarWidth);
  background-color: var(--sidebar-bg-color);
  position: relative;

}

.logout-link {
  border-radius: 50%;
}

.logout-link.icon {
  background: var(--primary-color);
  color: var(--primary-bg-color);
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  border-radius: 20px;
  position: absolute;
  left: 10px;
  bottom: 15px;
  font-size: 14px;
  cursor: pointer;
}

.sidebar-link.icon {
  color: var(--primary-color);
  font-size: 25px;
  margin-top: 7px;

  cursor: pointer;

}

.sidebar-links {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #6d6d6d;
}

.add-link {
  color: var(--primary-bg-color);
  font-size: 18px;
}

.add-link {
  color: var(--primary-bg-color);
  font-size: 18px;
  cursor: pointer;
}
.back-div
{
  width: 100%;
  position: fixed;
  height: 100vh;
  z-index: 1;
  background: #0000002e;
  z-index: 1;
  top:0;
  left: 0;
}

.ant-layout-has-sider,.chat-box,.ant-list
{
  height: calc(100vh - 64px);
} 

#OpenChannel {
  display: flex;
  flex-basis: 100%;
   flex-direction: unset !important; 
}
#participants, #messages
{
  overflow: auto;
}

.ant-layout-header { 
  background: white;
  border:1px solid #ccc ;
  line-height: 20px;
}

.ant-list-bordered
{
  border-radius: 0;
}
.ant-list-bordered{
  border:none ;
  border-right:1px solid #ccc ;
}
.left-side-chat-header{
  border-right:1px solid #ccc ;
}
.ant-input-group.ant-input-group-compact>*:first-child{
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}
.ant-input-group.ant-input-group-compact>*:last-child
{
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
} 
.chat-list-delete-add-icon
{ 
    padding: 8px 11px 9px 10px;
    border-radius: 50%;
}
.chat-list-delete-add-icon:hover
{  
  background: #efebff;
  color: #000;
}
.list-participents .ant-spin-nested-loading {
  height: 100%;
}
.list-participents .ant-spin-container{
  height: 100%;
  overflow: auto;
}

.chat-add-convesation{
  line-height:0;
  border-radius: 4px;   
  padding: 16px 13px;
  font-size: 15px;
}
.list-delete{
  background-color: #b60000 !important;
  border-color: #b60000 !important;
}

#chat-list-inner .ant-list-header{
  padding: 0;
}
.sidebar-link.icon > span {
font-size: 18px;
padding-left:10px ;
}
.sidebar-link.icon{
  margin: 0;
  display: flex;
  align-items: center;
}
.sidebar-links >div:first-child {
  margin-top:7px ;
}
.sidebar-links >div:hover { 
  background: #80808057;
  border-radius: 4px;
}
.sidebar-links >div {
  padding: 7px 10px;
  width: 100%;
}
.sidebar-links {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
#chat-list-header{
  max-width: 249px;
  min-width : 249px;
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.topbar{
  width: 100%;
  background-color: var(--primary-bg-color);
  color: white; 
  align-items: center; 
  padding: 8px;
  display: none;
  height: var(--topbarWidth);
}
.topbar-icon{
  padding-right: 25px;
}
.sidebar-close-icon{
  display: none;
}
.chat-bar-icon-close{
  display: none;
}
.chat-close-icon{
  display: none;
}
@media screen and (min-width: 799px) {
  .sideBar{
    display: block !important; 
  }
  #chat-list{
    display: block !important;
  }
  #chat-list-header
    {
    display: flex !important;

    }

}
.datatable{
  padding-top:10px;;
 }
@media screen and (max-width: 800px) {
  .chat-bar-icon-close{
    font-size:22px;padding:0px 19px 0px 0px;
    display: block;
  }
  .sidebar-close-icon{
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 25px;
    color: white;
    padding: 6px 8px; 
    border-radius: 50%;
  }
  .sidebar-close-icon:hover{
    background: #80808057;
  }
  :root { 
   --topbarWidth: 50px;
 }
 .custom-container{
  height: calc(100vh - var(--topbarWidth));
 }
 .sidebar-links-header{
  display: none;
 }
 .ant-layout-has-sider, .chat-box, .ant-list {
  height: calc(calc(100vh - 64px) - var(--topbarWidth));
}
 .sideBar{
  display: none; 
  position: fixed;
  z-index: 1000;
  box-shadow: 4px 5px 20px 0px rgba(0, 0, 0, 0.45);
 }
 .topbar{
  display: flex;
 }
 #chat-list,#chat-list-header{
  display: block; 
  position: fixed;
  background: white;
  z-index: 1000;
  top:47px;
  height: calc(100vh - 47px); 
  right: 0;
  box-shadow: -25px 17px 20px -20px rgba(0, 0, 0, 0.45)
 }
 #chat-list-header{
  z-index: 10000;
  height: 47px; 
  top:0px
  
 }
 #chat-list .ant-list {
  height: 100%;
}
 .custom-container {
  width: 100%;
 }
 .chat-close-icon{ 
  display: block;
  position: absolute;
  font-size: 28px;
  background: white;
  color: black;
  padding: 7px 10px;
  left: -41px;
  top: 5px;
 }
 .p-paginator-first,.p-paginator-last{

  display: none;
 }

}