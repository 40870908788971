.conversation-item {
  &:hover, &.conversation-item--active {
    background-color: #008CFF;
    cursor: pointer; 
    color: white;
    .conversation-item-text {
      color: white;
    }
  }
}
